// export const API_URL = 'http://localhost:7777/v3.0/';
// export const API_URL = 'https://api.gr8indeem.com/v3.0/';
// export const API_URL = 'https://api.kangzensmart.com/v3.0/';
// export const API_URL = 'https://api.glowxproject.com/v3.0/';
// export const API_URL = 'https://api.sabuymaster.com/v3.0/';
export const API_URL = 'https://api.loverinsure.com/v3.0/';

//  Admin
export const LOGIN = `${API_URL}admin/auth/login`;
export const REFRESH_TOKEN = `${API_URL}admin/auth/token`;

//  Overview
export const SUMMARY_STATS = `${API_URL}admin/overview/stats`;
export const OVERVIEW_LEADS = `${API_URL}admin/overview/leads`;
export const OVERVIEW_SHARES = `${API_URL}admin/overview/shares`;
export const OVERVIEW_MEMBER = `${API_URL}admin/overview/members`;
export const OVERVIEW_INCOME = `${API_URL}admin/overview/income`;

//  Events
export const EVENTS = `${API_URL}admin/events`;

//  New
export const NEWS = `${API_URL}admin/news`;
export const UPLOAD_NEWS_IMAGE = `${API_URL}admin/news/upload`;

//  Website
export const WEBSITE = `${API_URL}admin/website`;
export const UPLOAD_WEBSITE_IMAGE = `${API_URL}admin/website/upload`;
export const SHOPPING_WEBSITE = `${API_URL}admin/website/shopping`;

//  Content
export const CONTENT_CATEGORIES = `${API_URL}admin/content/categories`;
export const CONTENTS = `${API_URL}admin/content/contents`;
export const UPLOAD_CONTENT_IMAGE = `${API_URL}admin/content/contents/upload`;
export const UPLOAD_CONTENT_EDITOR_IMAGE = `${API_URL}admin/content/editors/upload`;
export const UPLOAD_CATEGORIES_IMAGE = `${API_URL}admin/content/categories/upload`;

//  Shopping
export const PRODUCT_CATEGORIES = `${API_URL}admin/shopping/categories`;
export const PRODUCTS = `${API_URL}admin/shopping/products`;
export const UPLOAD_PRODUCT_IMAGE = `${API_URL}admin/shopping/products/upload`;

export const ORDERS = `${API_URL}admin/orders`;

export const SHOPPING_BANNERS = `${API_URL}admin/shopping/banners`;
export const UPLOAD_SHOPPING_BANNER_IMAGE = `${API_URL}admin/shopping/banners/upload`;
export const SHOPPING_ARTICLES = `${API_URL}admin/shopping/articles`;
export const UPLOAD_SHOPPING_ARTICLE_IMAGE = `${API_URL}admin/shopping/articles/upload`;

export const GIFTS = `${API_URL}admin/redeem/gifts`;
export const UPLOAD_GIFT_IMAGE = `${API_URL}admin/redeem/gifts/upload`;
export const REDEEM_HISTORIES = `${API_URL}admin/redeem/histories`;
export const REDEEM_CLEAR_POINT = `${API_URL}admin/redeem/clear`;

//  Guest
export const GUESTS = `${API_URL}admin/guest/teams`;
export const GUESTS_ORDERS = `${API_URL}admin/guest/orders`;
export const GUESTS_UPGRADE = `${API_URL}admin/guest/upgrade`;
export const GUEST_WEBSITE = `${API_URL}admin/website/guest`;

//  Member
export const MEMBER_GROUPS = `${API_URL}admin/member/groups`;
export const UPLOAD_MEMBER_GROUPS_IMAGE = `${API_URL}admin/member/groups/upload`;
export const MEMBER_TEAMS = `${API_URL}admin/member/teams`;
export const MEMBER_TEAMS_NETWORK = `${API_URL}admin/member/teams/network`;
export const MEMBER_TEAMS_UPLOAD = `${API_URL}admin/member/teams/upload`;
export const MEMBER_PROFILE = `${API_URL}admin/member/profile`;
export const MEMBER_CONTENTS = `${API_URL}admin/member/contents`;
export const MEMBER_TEAMS_CHANGE_PASSWORD = `${API_URL}admin/member/teams/password`;
export const MEMBER_QUALIFIED = `${API_URL}admin/member/qualified`;
export const MEMBER_QUOTA = `${API_URL}admin/member/quota`;
export const MEMBER_QUOTA_SETTINGS = `${API_URL}admin/member/quota/settings`;
export const MEMBER_WEBSITE = `${API_URL}admin/website/member`;

//  Page
export const PAGES = `${API_URL}admin/pages`;

//  KPI
export const KPI_ACHIEVEMENTS = `${API_URL}admin/kpi/achievements`;
export const KPI_LEADERS = `${API_URL}admin/kpi/leaders`;

//  Email
export const EMAIL_CATEGORIES = `${API_URL}admin/email/categories`;
export const EMAIL_TEMPLATES = `${API_URL}admin/email/templates`;
export const EMAIL_SETTINGS = `${API_URL}admin/email/settings`;
export const EMAIL_SEND_TESTING = `${API_URL}admin/email/send/testing`;

//  Liff
export const LIFF_CATEGORIES = `${API_URL}admin/liff/categories`;
export const LIFF_CONTENTS = `${API_URL}admin/liff/contents`;
export const UPLOAD_LIFF_IMAGE = `${API_URL}admin/liff/upload`;
export const UPLOAD_LIFF_CONTENTS_IMAGE = `${API_URL}admin/liff/contents/upload`;

//  Setting
export const SETTINGS = `${API_URL}admin/setting`;

//  Other article
export const ARTICLES = `${API_URL}admin/articles`;

//  Common
export const COMMON_BOXES = `${API_URL}common/shipping/boxes`;
export const COMMON_COURIERS = `${API_URL}common/shipping/couriers`;
export const COMMON_PAYMENT_METHODS = `${API_URL}common/payment/methods`;
export const COMMON_PAYMENT_BANKS = `${API_URL}common/payment/banks`;

export const CUSTOMER_ORDERS = `${API_URL}member/orders/customer`;
export const COURIERS_PRICE = `${API_URL}member/orders/customer/check-price`;


export const NAV = `${API_URL}data/menu.json`;
